.ScaleBackgroundDialog{
  text-align: center;

  h3 {
      margin-bottom: 1em;
  }

  label{
    box-sizing: border-box;
    margin-right: 1em;
  }

  input {
      box-sizing: border-box;
      display: inline-block;
      margin-right: .5em;
      width: 100px;
  }

}

.modal-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  transform: translateZ(0);
  background-color: rgba(58,58,58,0.75);

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &-header{
      text-align: left;
      width: 100%;
      background-color: #EBEBEB;
      color: #050038;
      text-transform: uppercase;
      font-family: $Korolev;
      font-weight: bold;
      font-size: 18px;
      padding: 10px;
      margin: 0 0 10px 0;
    }

    &-content {
        padding: 10px 40px;
    }

    &-buttons {
        padding: 20px 0;

        button {
            width: auto;
        }

        .cancelButton {
          float: left;
        }

        .confirmButton {
            float: right;
        }

        &:after {
            content: '';
            clear: both;
            display: block;
            height: 0;
        }
    }

    @media screen and (min-width: 500px) {
      left: 50%;
      top: 50%;
      height: auto;
      transform: translate(-50%, -50%);
      max-width: 40em;
      max-height: calc(100% - 1em);
    }
  }
}

@import "src/theme";

.modal.copy-modal {
  width: 50%;
  max-width: 400px;
  min-width: 300px;
  border-radius: 5px;
}

button {
  &.disabled {
    pointer-events: none;
    background-color: #ebebeb;
  }
}

.CopyModal {
  width: 100%;
  text-align: center;
  height: 100%;

  .copybox {
    margin-bottom: 20px;

    .copytext {
      margin-bottom: 10px;
    }

    .loading_icon {
      font-size: 20px;
      min-height: 30px;

      @keyframes spinner {
        0% {
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(90deg);
        }
        50% {
          transform: rotate(180deg);
        }
        75% {
          transform: rotate(270deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      .icon-spinning {
        animation: spinner infinite 1.1s linear;
      }

    }

  }


  p {
    font-size: 14px;
  }


  .format {
    display: inline-block;

    .export-options {
      display: inline-grid;
      padding: 15px 0;
    }
  }

  .format fieldset {
    border: none;
  }

  .title-text {
    margin-top: 20px;
    @include h5;
  }

  .sub-title-text {
    padding: 5px 0;
  }

  .format .export-options label {
    text-transform: none;
    line-height: 20px;
    display: block;
    border: none;
    padding: 5px;
    margin-left: -10px;

    .maintext {
      font-size: 1.2em;
    }

    .subtext {
      font-size: 1.1em;
      display: block;
      margin-left: 15px;
      font-style: italic;
    }
  }

  .format fieldset label span {

    margin-left: 5px;
  }

}

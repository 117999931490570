.LoadModal{

    button{
      display: block;
      margin: 50px auto 0;
    }
    i{
      margin: 5px auto;
      display: block;
      width: 75%;
    }
  label{
    margin: 50px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  input{
    display: none;
  }
}

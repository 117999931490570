@import "src/theme";
.MenuBar{
  position: relative;
  z-index: 999;
  pointer-events: all;
  height: 40px;
  .container{
    height: 100%;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    display: inline-flex;
  }
  .title{
    border-radius: 5px 0 0 5px;
    background-color: #050038;
    display: flex;
    h4{
      margin: 0 10px;
      color: white;
      font-size: 24px;
      align-self: center;
      white-space: nowrap;
    }
  }
  .hz-tb .sq:first-child, .hz-tb .sq:last-child{
    width: auto;
  }
  .ss-button.sq{
    height: auto;
    width: auto;
    margin: 0 7px;
  }
  .filename {
      display: flex;
      margin: 0;
      align-self: center;
      svg{
        margin: 0 10px;
      }
      input {
          color: $input-color;
          border: 1px solid #D9D9D9;
          height: 100%;
          border-radius: 5px;
          padding: 2px 6px;
      }
  }
  .menu-items{
    box-shadow: none;
  }
  button {
    background-color: #ffffff;
    border: none;
    i, label{
      cursor: pointer;

      &.active {
          color: red;// #4E28C4;
      }
    }
    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-theme;
    }
  }
  .LoadDialog{
    label {
      background-color: #ffffff;
      border: none;
      margin: 0;
      font-size: 20px;
      &:focus {
        outline: none;
      }
    }
    input{
      display: none;
    }
  }

  .active-users-button {
      &:focus, &:hover {
        color: inherit !important;
      }
  }

  .user-count {
      position: absolute;
      top: 0;
      right: 5px;

      width: 14px;
      height: 14px;
      padding-top: 0px;
      padding-left: 1px;

      color: red;//#4E28C4;
      font-size: 10px;
      font-weight: bold;
      font-family: $Korolev;

      background: white;//#eaeaea;
      border: 1px solid red;
      border-radius: 50%;
  }
}


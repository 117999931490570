@import "~@sasaki-dev/design-system/styles/_styles";
@import "~react-table/react-table.css";

@import "./components/_index";

html, body, #app-root, .App, .Stage{
  width: 100%;
  min-height: 100%;
  height: 100vh;
  overflow: hidden;
}

.Overlay{
  position: absolute;
  top: 0;
  width: 100%;
  overflow: hidden;
  height: 100%;
  display: grid;
  grid-template-columns: auto 2fr 1fr auto;
  grid-template-rows: auto auto 1fr;

  .controls-wrapper{
    display: inline-grid;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    height: 60px;
    z-index: 102;
    pointer-events: none;

    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-column-start: 1;
    grid-column-end: 4;
  }
}

.SaveDialog{
  text-align: center;
  .text-input{
    text-align: left;
    label{
      font-weight: 700;
      text-transform: uppercase;
    }
    input{
      box-sizing: border-box;
      background-color: #ebebeb;
      border: none;
      width: 100%;
    }
  }

  p.note{
    font-style: italic;
  }
  p.directions{
    margin: 0 5%;
  }
}
